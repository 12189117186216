import React, { useEffect, useRef, useState, useMemo } from 'react';
import { TranslatedLink } from '../TranslatedLink'
import * as styles from "./Styles.module.scss"
import LinkBtn from '../linkBtn/LinkBtn';
import snarkdown from 'snarkdown'
import VisibilitySensor from 'react-visibility-sensor'

const BlockSwitcher = ({ items, reverze, speed }) => {

  const [count, setCount] = useState(0);
  const [blockIndex, setBlockIndex] = useState(0);
  const [stop, setStop] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    let interval
    if (isVisible) {
      interval = setInterval(() => {
        setCount((prevCounter) => {
          if (stop) return prevCounter
          
          if (prevCounter + 1 >= 100) {
            setBlockIndex((prevIndex) => {
              if (prevIndex + 1 >= items.length) {
                return 0
              } else {
                return prevIndex + 1
              }
            });
            setCount(0);
            return 0
          }
          return prevCounter + speed
        });

      }, 1);
    }

    return () => {
      clearInterval(interval)
    };
  }, [isVisible, items, stop]);

  function onChange(isVisible) {
    setIsVisible(isVisible)
  }

  const pointActivate = (i) => {
    setCount(0)
    setBlockIndex(i)
  }

  return (
    <VisibilitySensor onChange={onChange} offset={{bottom: 200, top: 400}} partialVisibility={true}>
      <div className={`${styles.cont} ${reverze ? 'reverze' : ''}`} onMouseEnter={() => setStop(true)} onMouseLeave={() => setStop(false)}>
        {items.map((item, key) =>
          <div key={key} className={`${styles.point} ${key === blockIndex ? styles.activePoint : ''}`} onClick={() => pointActivate(key)}>
            <div className={`${styles.counter}`} style={{ height: `${count}%` }} />
            <p className={`mb-1 ${styles.small}`}><b>{item.subtitle}</b></p>
            <h4 className='mb-3'>{item.title}</h4>
            <div className={`${styles.blockDescription} ${key === blockIndex ? styles.active : ''}`}>
              <p className={`${styles.paragraph}`} dangerouslySetInnerHTML={{ __html: snarkdown(item.description) }} />
                <div className={styles.buttons}>
                  {item.link &&
                    <LinkBtn size={1.1}>
                      <TranslatedLink to={item.link}>{item.link_name}</TranslatedLink>
                    </LinkBtn>
                  }
                </div>
            </div>
          </div>
        )}
      </div>
    </VisibilitySensor>
  )
}

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () => {
      if (typeof window !== 'undefined') {
        return new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        )
      }
      return []
    }
    ,
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

BlockSwitcher.defaultProps = {
  items: [],
  reverze: false,
  speed: 0.025
}

export default BlockSwitcher

