import React from 'react'
import Layout from '../../layout'
import { getTranslateFunction, scrollToTarget, toTwoDigit } from '../../../utilities'
import * as styles from "./Styles.module.scss"
import HoverBtn from '../../HoverBtn'
import SeoHelmet from '../../seoHelmet'
import { TranslatedLink } from '../../TranslatedLink'
/* import Testimonials from '../../testimonials/Testimonials' */
import SideMenu from '../../sideMenu/side-menu'
import ReactVivus from 'react-vivus'
/* import ContactForm from '../../contactForm/ContactForm' */
import BlockSwitcher from '../../blockSwitcher/BlockSwitcher'
import Reveal from '../../reveal'
import ContactUs from '../../contactUs/ContactUs'

const NrtvsTemplate = ({ data, lang }) => {

  let nrtvsFile = data.nrtvs.childMarkdownRemark.frontmatter
  let saidAboutUs = data.saidAboutUs.childMarkdownRemark.frontmatter

  const t = getTranslateFunction(lang)
  
  const scrollBotton = () => {
    scrollToTarget(document.getElementById('content'), 600)
  }

  return (
    <Layout lang={lang}>
      <SeoHelmet
        description={nrtvsFile.seo_description}
        title={nrtvsFile.seo_title}
      />

      <div className={styles.header}>
        <div className={'container ' + styles.sectionContainer}>
          <div>
            <div className='subpage-title mb-5 pb-5'>
              <h1 dangerouslySetInnerHTML={{ __html: nrtvsFile.title }}></h1>
              <p dangerouslySetInnerHTML={{ __html: nrtvsFile.subtitle }}></p>
              <p className="description-smaller pr-0" dangerouslySetInnerHTML={{ __html: nrtvsFile.description }}></p>
            </div>
            <HoverBtn size={1.25} handleClick={scrollBotton}>
              <div className={styles.btnInner}>
                <span dangerouslySetInnerHTML={{ __html: nrtvsFile.button }} />
                <img src="/images/svg/long_down.svg" alt="arrow" className='arrow' />
              </div>
            </HoverBtn>
          </div>
        </div>
      </div>

      <div id="content" className={`${styles.itemContent}`}>
        <div className='row'>
          <div className={`side-menu-height-reference ${styles.sideMenuFixer}`} id="left">
            <SideMenu lang={lang} isGhost={false} />
          </div>
          <div className='col-12'>
            {nrtvsFile.list.map(function (list, i) {
              return (
                <Reveal otherClassName={`${styles.section}`}>
                  <section className={`${i % 2 !== 0 ? styles.odd : ''}`}>

                    <img src="/images/svg/nrtvs-bg.svg" alt="bg" className='bg' />
                    <div className={'container ' + styles.sectionContainer}>
                      <div class="row">
                        <div className={`col-xl-6 ${styles.leftSide}`}>
                          <div>
                            <h3 className='mb-5'><span className={styles.num}>{toTwoDigit(i + 1)}</span>{list.title}</h3>
                            <h4 className='mb-2'>{list.subtitle}</h4>
                            <p>{list.description}</p>
                          </div>
                          <div className={`${styles.icon}`}>
                            <ReactVivus
                              className={`svg-img vivus svg-${i}`}
                              id={list.title}
                              option={{
                                start: 'inViewport',
                                delay: 0,
                                file: list.icon.publicURL,
                                animTimingFunction: 'EASE',
                                type: 'delayed',
                                duration: 100,
                              }}
                              style={{ height: '150px', width: '150px' }}
                            />
                          </div>
                        </div>
                        <div className={`col-xl-6`}>
                          <BlockSwitcher items={list.items} reverze={i % 2 !== 0} />
                        </div>
                      </div>
                    </div>
                  </section>
                </Reveal>
              )
            })}
          </div>
        </div>

      </div>
      <Reveal otherClassName={`mb-0`}>
        <div className={`d-flex justify-content-center my-5 `}>
          <HoverBtn size={1.25}>
            <TranslatedLink to='/sluzby'>Prezrite naše služby</TranslatedLink>
          </HoverBtn>
        </div>
      </Reveal>

       <Reveal>
          <ContactUs
            blockClassName={'services'}
            LeftSideTitle={t('References.MoreProjects.Title')}
            LeftSideSubTitle={'UX princípy'}
            LeftSideLink={'/o-nas/ux-principy'}
            linkRight="/en/contact"
            rightSecondLink="/en/brief"
          />
        </Reveal>

    {/*   <Reveal otherClassName={`mt-5 mb-0`}>
        <Testimonials
          enableSaidAboutUs={true}
          references={saidAboutUs.references}
          paragraph={saidAboutUs.text_paragraph}
        />
      </Reveal>
 */}
 {/*      <Reveal>
        <ContactForm />
      </Reveal>
 */}
    </Layout>
  )
}


export default NrtvsTemplate






