// extracted by mini-css-extract-plugin
export var arrow = "Styles-module--arrow--hYCAA";
export var btnInner = "Styles-module--btnInner--biM9f";
export var header = "Styles-module--header--qrSXU";
export var icon = "Styles-module--icon--X3skY";
export var itemContent = "Styles-module--itemContent--RYrh3";
export var leftSide = "Styles-module--leftSide--1zsM-";
export var num = "Styles-module--num--tFlkl";
export var odd = "Styles-module--odd--wucuK";
export var section = "Styles-module--section--hyAPe";
export var sectionContainer = "Styles-module--sectionContainer--dKDeq";
export var sideMenuFixer = "Styles-module--sideMenuFixer--YBV+7";