import React from 'react'
import NrtvsTemplate from '../../components/pages/how-we-work/how-we-workTemplate'
import { graphql } from 'gatsby'

const Nrtvs = ({ data }) => (
  <NrtvsTemplate data={data} lang={'sk'} />
)


export const query = graphql`{
  nrtvs: file(relativePath: {eq: "pages/sk/about-us/how_we_work.md"}) {
    childMarkdownRemark {
      frontmatter {
        seo_title
        seo_description
        title
        subtitle
        description
        button
        list {
          title
          subtitle
          description
          icon{
            publicURL
          } 
          items {
            title
            subtitle
            description
            link_name
            link
          }
        }
      }
    }
  }
  saidAboutUs: file(relativePath: {eq: "pages/sk/homepage/said_about_us.md"}) {
    childMarkdownRemark {
        frontmatter {
            text_paragraph
            references {
                description
                logo: logo {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                logoBig: logo {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                author
            }
        }
    }
  }
}
`

export default Nrtvs
