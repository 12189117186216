// extracted by mini-css-extract-plugin
export var active = "Styles-module--active--4QAO2";
export var activePoint = "Styles-module--activePoint--xHH9+";
export var blockDescription = "Styles-module--blockDescription--NahxW";
export var buttons = "Styles-module--buttons--+09xE";
export var cont = "Styles-module--cont--6I9qg";
export var counter = "Styles-module--counter--j1KDf";
export var leftSide = "Styles-module--leftSide--PhmAA";
export var paragraph = "Styles-module--paragraph--o8bMU";
export var point = "Styles-module--point--P8xZG";
export var reverze = "Styles-module--reverze--xs9J4";
export var small = "Styles-module--small--dpqdM";